import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "../home";
import Activation from "../activation";
import ResetPassword from "../reset";


function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web/auth/activation" element={<Activation />} />
        <Route path="/web/auth/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Home />} />
      </Routes>
  );
}

export default App;
