import React from 'react';

function Home() {
  return (
    <div className="page">
      <header className="page-header">
        <p>
          Сайт знаходиться в розробці
        </p>
      </header>
    </div>
  );
}

export default Home;
