import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

function Reset() {
  const [pending, setPending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (!code) navigate('/');
  }, [navigate])

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    setPending(true);

    fetch(`${process.env.REACT_APP_URL}/api/auth/reset_password/confirm`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code, password, password_confirm: password})
    }).then(response => {
      const isSuccess = response.status < 400;
      setSuccess(isSuccess);
      response.json().then(data => {
        try {
          const msg = data.errors[0].messages[0];
          setError(msg);
        } catch {
          setError("Виникла помилка, спробуйте знову");
        }
      });
    }).catch(error => {
      setError("Виникла помилка, спробуйте знову");
      console.log(error)
    }).finally(() => {
      setPending(false)
    })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target?.value);
  }

  return (
    <div className="page">
      <header className="page-header">
        {pending ? (
          <p>Скидання паролю...</p>
        ) : (
          success ? (
            <>
              <p className="blue">Пароль успішно змінено</p>
              <p>Тепер ви можете згорнути браузер і спробувати увійти в додатку</p>
            </>
          ) : (
            <div>
              {
                error ? (<p className="red">{error}</p>) : null
              }
              <form onSubmit={submit}>
                <p>Введіть новий пароль:</p>
                <p>
                  <input type="password" required name="password" value={password} onChange={handleChange}/>
                </p>
                <p>
                  <button type={"submit"}>Відправити</button>
                </p>
              </form>
            </div>
          )
        )}
      </header>
    </div>
  );
}

export default Reset;
