import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

function Activation() {
  const [pending, setPending] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (!code) navigate('/');
    fetch(`${process.env.REACT_APP_URL}/api/auth/activate`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code})
    }).then(response => {
      setSuccess(response.status < 400);
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setPending(false)
    })
  }, [navigate])

  return (
    <div className="page">
      <header className="page-header">
        {pending ? (
          <p>Активація аккаунта...</p>
        ) : (
          success ? (
            <>
              <p className="blue">Аккаунт успішно активовано</p>
              <p>Тепер ви можете згорнути браузер і спробувати увійти в додатку</p>
            </>
          ) : (
            <p>Невірний код активації або аккаунт вже активовано</p>
          )
        )}
      </header>
    </div>
  );
}

export default Activation;
